import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router'
import { fetchAllUsersPermissions, updateUserMapping, userDataToPathPermissions } from '../../api'
import { useAuth } from '../../context/AuthContext'
import RouteIcon from '../../images/Route Icon.svg'
import Customer from '../../images/customer.svg'
import Operations from '../../images/operation.svg'
import Finance from '../../images/finance.svg'
import ILP from '../../images/ilp.svg'
import './UserPermissions.css'

export const allUserPaths = {
  routing: {
    text: 'Routing',
    link: '#',
    icon: RouteIcon,
    subpaths: {
      ad_file_reation: { text: 'AD File Creation', link: '/home/adfile' },
      metro_routes: { text: 'Metro Routes', link: '/home/metro_routes' },
      route: { text: 'Routes (Planned vs Actual)', link: '/home/route' },
      rts: { text: 'RTS', link: '/home/rts' },
      set_driver_schedule: { text: 'Set Driver Schedule', link: '/home/set_driver_schedule' },
    },
  },
  ilp: {
    text: 'ILP',
    link: '#',
    icon: ILP,
    subpaths: {
      driver_mapping: { text: 'Driver Mapping', link: '/home/driver_mapping' },
      billing: { text: 'Billing', link: '/home/billing' },
      rate_list: { text: 'Rate List', link: '/home/rate_list' },
      zipcode_mapping: { text: 'Zipcode Mapping', link: '/home/zipcode_mapping' },
      middle_miles: { text: 'Middle Miles', link: '/home/middle_miles' },
      grievance: { text: 'Grievance', link: '/home/grievance' },
    },
  },
  customer: {
    text: 'Customers',
    link: '#',
    icon: Customer,
    subpaths: {
      rate_list: { text: 'Rate List', link: '/home/customer_rate_list' },
      invoicing: { text: 'Invoicing', link: '/home/invoicing' },
      grievance: { text: 'Grievance', link: '/home/customer_grievance' },
    },
  },
  OperationsReview: {
    text: 'Operations Review',
    link: '#',
    icon: Operations,
    subpaths: {
      delivery_operations_kpis: { text: 'Delivery Operations KPIs', link: '/home/delivery_operations_kpis' },
      customer_delivery_kpis: { text: 'Customer Delivery KPIs', link: '/home/customer_delivery_kpis' },
      birds_eye_view: { text: "Bird's Eye View", link: '/home/birds_eye_view' },
    },
  },
  financial_review: {
    text: 'Financial Review',
    link: '#',
    icon: Finance,
    subpaths: {
      revenue_dashboard: { text: 'Revenue Dashboard', link: '/home/revenue_dashboard' },
      expenses_dashboard: { text: 'Expanses Dashboard', link: '/home/expenses_dashboard' },
      profitability_dashboard: { text: 'Profitability Dashboard', link: '/home/profitability_dashboard' },
    },
  },
}

function UserPermissionBox({ user, sectionName, subSections, pathPermissionsUpdater }) {
  return (
    <div className="permissions-container">
      <div className="permissions-heading">{allUserPaths[sectionName]?.text}</div>
      <div className="permissions-list">
        {Object.entries(subSections).map(([subKey, checked]) => {
          const inputID = `${user.id}-${sectionName}-${subKey}`
          return (
            <div className="permissions-item" key={subKey}>
              <input
                id={inputID}
                type="checkbox"
                name={inputID}
                checked={checked}
                onChange={() => pathPermissionsUpdater(sectionName, subKey)}
              ></input>
              <label htmlFor={inputID} className="permission-label">
                {allUserPaths[sectionName]['subpaths'][subKey].text}
              </label>
              <div />
            </div>
          )
        })}
      </div>
    </div>
  )
}

function UserDropdown({ user }) {
  const [userPathPermissions, setUserPathPermissions] = useState(user.pathPermissions)

  async function updateDB(pathPermissions) {
    const dbFields = []
    for (const skey in pathPermissions) {
      for (const subkey in pathPermissions[skey]) {
        const value = pathPermissions[skey][subkey] ? '1' : '0'
        let fieldName = `${skey}_${subkey}`

        dbFields.push({ fieldName, value })
      }
    }
    return await updateUserMapping(user.username, dbFields)
  }

  async function pathPermissionsUpdater(sectionKey, subSectionKey) {
    const oldUserPathPermissions = { ...userPathPermissions }
    const newUserPathPermissions = {}
    for (let skey in userPathPermissions) {
      for (let subkey in userPathPermissions[skey]) {
        if (skey === sectionKey && subkey === subSectionKey) {
          newUserPathPermissions[skey] = {
            ...newUserPathPermissions[skey],
            [subkey]: !userPathPermissions[skey][subkey],
          }
        } else {
          newUserPathPermissions[skey] = {
            ...newUserPathPermissions[skey],
            [subkey]: userPathPermissions[skey][subkey],
          }
        }
      }
    }

    setUserPathPermissions(newUserPathPermissions)
    try {
      const [status, data] = await updateDB(newUserPathPermissions)
      if (status !== 200) setUserPathPermissions(oldUserPathPermissions)
      else console.log('Success updating permissions')
    } catch (e) {
      // console.log(e)
      console.log('Error updating permissions')
      setUserPathPermissions(oldUserPathPermissions)
    }
  }

  useEffect(() => {
    document.getElementById(user.id).addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('active')
      e.currentTarget.nextSibling.classList.toggle('active')
    })
  }, [])

  return (
    <div className="user-container">
      <div id={user.id} className="user-top">
        <div className="user-left">
          <div className="user-image">
            <img src={user.imageURL} alt="user" referrerPolicy="no-referrer" />
          </div>
          <div className="user-name">{user.name}</div>
        </div>

        <div className="user-right">
          <i className="fas fa-chevron-down"></i>
        </div>
      </div>

      <div className="user-permissions-section">
        <div className="user-permissions-container">
          {Object.entries(userPathPermissions).map(([sectionName, subSections]) => {
            return (
              <UserPermissionBox
                key={sectionName}
                user={user}
                sectionName={sectionName}
                subSections={subSections}
                pathPermissionsUpdater={pathPermissionsUpdater}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default function UserPermissions() {
  const { superUser } = useAuth()
  const location = useLocation()

  const [users, setUsers] = useState([])

  async function getUsers() {
    const allUsersData = await fetchAllUsersPermissions()

    const allUsers = allUsersData
      .map((userData) => {
        const { is_superuser, username } = userData.user[0]

        // super users are removed from the list
        if (is_superuser) return

        const { id, email, verified_email, name, picture } = userData.social[0].extra_data

        const pathPermissions = userDataToPathPermissions(userData)

        return {
          id,
          name,
          username,
          email,
          verified_email,
          imageURL: picture,
          pathPermissions,
        }
      })
      .filter((e) => e)

    setUsers(allUsers)
  }

  useLayoutEffect(() => {
    getUsers()
  }, [])

  if (!superUser) return <Navigate to="/home" state={{ from: location }} replace />

  return (
    <div className="content-wrapper" style={{ marginTop: '50px', minHeight: 'calc(100vh - 108px)' }}>
      <div className="users-container-top">
        <div className="users-title">User Permissions</div>
        <div className="users-path">
          <a href="#">Admin</a>/<span>User Permissions</span>
        </div>
      </div>
      <div className="users-container">
        {users
          .sort((userA, userB) => userA.name.localeCompare(userB.name))
          .map((user) => {
            return <UserDropdown key={user.id} user={user} />
          })}
      </div>
    </div>
  )
}
