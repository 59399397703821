import React from 'react'
import Logo from '../images/BT Icon Logo.svg'
import GoogleIcon from '../images/google-icon.png'
import { useAuth } from '../context/AuthContext'
import { Navigate } from 'react-router-dom'
import { LOGIN_URL } from '../api'

export default function Login() {
  const { loggedIn } = useAuth()

  if (loggedIn) return <Navigate to="/home" />

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div
          className="login-logo"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '.2rem',
          }}
        >
          <img
            src={Logo}
            alt="AdminLTE Logo"
            className="brand-image"
            style={{ height: '60px', width: '60px', opacity: '.9' }}
          />
          <span
            className="brand-text font-weight-bold"
            style={{
              color: 'rgb(57, 57, 56)',
              fontSize: '35px',
              marginTop: '1rem',
              fontFamily: 'Bahnschrift',
            }}
          >
            BETTER TRUCKS
          </span>
        </div>
        <div className="card">
          <div
            className="card-body login-card-body"
            style={{
              borderRadius: ' 20px',
            }}
          >
            <p className="login-box-msg">Sign in to start your session</p>

            <div className="social-auth-links text-center mb-3">
              <a
                href={LOGIN_URL}
                className="btn btn-default"
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#f8f9fa',
                  borderColor: '#ddd',
                }}
              >
                <img src={GoogleIcon} alt="GoogleIcon" style={{ height: '20px', width: '20px' }} /> Sign in using Google
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
