import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './context/AuthContext'

export default function PrivateRoute() {
  const { loggedIn } = useAuth()
  const location = useLocation()

  if (!loggedIn) return <Navigate to="/login" state={{ from: location }} replace />

  return <Navigate to="/home" state={{ from: location }} replace />
}
