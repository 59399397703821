import React from 'react'

export default function Footer() {
  return (
    <footer
      className="main-footer"
      style={{
        zIndex: 1032,
      }}
    >
      <strong>2022 © All Rights Reserved | Better Trucks</strong>
    </footer>
  )
}
