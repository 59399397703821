import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { useAuth } from '../../context/AuthContext'
import Footer from '../Footer/Footer'
import Sidebar from '../Sidebar/Sidebar'

export default function Home() {
  const { loggedIn, currentUserPathPermissions, superUser } = useAuth()
  const location = useLocation()

  if (!loggedIn) return <Navigate to="/login" state={{ from: location }} replace />

  if (superUser)
    return (
      <>
        <Sidebar />
        <Outlet />
        <Footer />
      </>
    )

  // Routing
  if (location.pathname.includes('/home/adfile') && !currentUserPathPermissions.routing.ad_file_reation)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/metro_routes') && !currentUserPathPermissions.routing.metro_routes)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/route') && !currentUserPathPermissions.routing.route)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/rts') && !currentUserPathPermissions.routing.rts)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (
    location.pathname.includes('/home/set_driver_schedule') &&
    !currentUserPathPermissions.routing.set_driver_schedule
  )
    return <Navigate to="/home" state={{ from: location }} replace />

  // ILP
  if (location.pathname.includes('/home/driver_mapping') && !currentUserPathPermissions.ilp.driver_mapping)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/billing') && !currentUserPathPermissions.ilp.billing)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/rate_list') && !currentUserPathPermissions.ilp.rate_list)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/zipcode_mapping') && !currentUserPathPermissions.ilp.zipcode_mapping)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/middle_miles') && !currentUserPathPermissions.ilp.middle_miles)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/grievance') && !currentUserPathPermissions.ilp.grievance)
    return <Navigate to="/home" state={{ from: location }} replace />

  // Customers
  if (location.pathname.includes('/home/customer_rate_list') && !currentUserPathPermissions.customer.rate_list)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/invoicing') && !currentUserPathPermissions.customer.invoicing)
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/customer_grievance') && !currentUserPathPermissions.customer.grievance)
    return <Navigate to="/home" state={{ from: location }} replace />

  // Operations Review
  if (
    location.pathname.includes('/home/delivery_operations_kpis') &&
    !currentUserPathPermissions.OperationsReview.delivery_operations_kpis
  )
    return <Navigate to="/home" state={{ from: location }} replace />

  if (
    location.pathname.includes('/home/customer_delivery_kpis') &&
    !currentUserPathPermissions.OperationsReview.customer_delivery_kpis
  )
    return <Navigate to="/home" state={{ from: location }} replace />

  if (location.pathname.includes('/home/birds_eye_view') && !currentUserPathPermissions.OperationsReview.birds_eye_view)
    return <Navigate to="/home" state={{ from: location }} replace />

  // Financial Review
  if (
    location.pathname.includes('/home/revenue_dashboard') &&
    !currentUserPathPermissions.financial_review.revenue_dashboard
  )
    return <Navigate to="/home" state={{ from: location }} replace />

  if (
    location.pathname.includes('/home/expenses_dashboard') &&
    !currentUserPathPermissions.financial_review.expenses_dashboard
  )
    return <Navigate to="/home" state={{ from: location }} replace />

  if (
    location.pathname.includes('/home/profitability_dashboard') &&
    !currentUserPathPermissions.financial_review.profitability_dashboard
  )
    return <Navigate to="/home" state={{ from: location }} replace />

  return (
    <>
      <Sidebar />
      <Outlet />
      <Footer />
    </>
  )
}
