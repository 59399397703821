import axios from 'axios'

// let API_URL = 'https://app.resources.bettertrucks.com'
// let API_URL = 'https://flodata-django-service-t6zvxykbta-uc.a.run.app'
let API_URL = 'https://flodata-django.bettertrucks.com'


if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000'

const axiosInstance = axios.create({ baseURL: API_URL })

// export const LOGIN_URL = `https://flodata-django-service-t6zvxykbta-uc.a.run.app/accounts/google/login/?process=login`
// export const LOGOUT_URL = `https://flodata-django-service-t6zvxykbta-uc.a.run.app/logout`

export const LOGIN_URL = `https://flodata-django.bettertrucks.com/accounts/google/login/?process=login`
export const LOGOUT_URL = `https://flodata-django.bettertrucks.com/logout`

export async function fetchLoggedIn() {
  const r = await axiosInstance.get('/check_LoggedIn/', { withCredentials: true })
  return r.data
}

export function userDataToPathPermissions(userData) {
  const pathPermissions = Object.entries(userData).reduce((acc, [path, value]) => {
    if (['user', 'social', 'LoggedIn'].includes(path) || value.length === 0) return acc
    value = value[0]
    delete value.id
    delete value.user_id
    acc[path] = value
    return acc
  }, {})

  return pathPermissions
}

export async function fetchUser() {
  try {
    const r = await axiosInstance.get('/check_Socials/', { withCredentials: true })
    return { user: r.data.LoggedIn[0], userPathPermissions: userDataToPathPermissions(r.data) }
  } catch (e) {
    return { user: null, userPathPermissions: null }
  }
}

export async function updateUserMapping(username, updatedData) {
  const formData = new FormData()
  formData.append('username', username)
  formData.append('fields', '1')

  for (const { fieldName, value } of updatedData) {
    formData.append(fieldName, value)
  }

  const r = await axiosInstance.post('/check_modules/', formData)
  return [r.status, r.data]
}

export async function fetchAdFileData(requestString, startDate, endDate) {
  if (process.env.NODE_ENV === 'development') {
    const r = await axios.get('/data.json')
    return r.data[0]
  } else {
    const r = await axiosInstance.get(`/create_routes/${requestString}/${startDate}/${endDate}/`)
    return r.data.result[0]
  }
}

export async function fetchAllUsersPermissions() {
  const r = await axiosInstance.get('/get_users/', { withCredentials: true })
  return r.data.data
}

export async function addAdFileDataToDB(data) {
  const r = await axiosInstance.post('/load_ad_file_data/', data, { withCredentials: true })
  return r.data.result
}
